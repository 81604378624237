import './LandingPage.scss'

import Layout from 'EasyProjectGatsby/components/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import { LinkType } from 'Shared/types/Link'
import {
  AnchorItemType,
  LinkItemType,
  menuItemsMapper,
} from 'Shared/utils/menuItemsMapper'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import { HeaderData } from '../../components/core/HeaderNav'

export interface LandingType {
  displayText: string
  contentType: string
  contentItemId: string
  path: string
  landingPageButton: LinkType
  landingPageAnchors: {
    contentItems: (AnchorItemType | LinkItemType)[]
  }
}

export interface LandingPageType {
  data: {
    orchard: {
      puxLandingPage: [LandingType]
    }
  }
  pageContext: {
    isoCode: string
    metaTags: PuxMetaTagsType
    widgets: {
      landingWidgets: [WidgetPuxSectionType]
    }
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxLandingPage(first: 1, where: { path: $pagePath }) {
        contentType
        contentItemId
        path
        displayText
        landingPageButton {
          text
          url
        }
        landingPageAnchors {
          contentItems {
            ... on Orchard_EasySoftwareAnchorItem {
              displayText
              contentType
              anchorItemTarget
            }
            ... on Orchard_EasySoftwareLinkItem {
              contentType
              linkItemTarget {
                internal
                text
                url
              }
            }
          }
        }
      }
    }
  }
`

const rootClass = `landing-page`

const LandingPage: FunctionComponent<LandingPageType> = (props) => {
  const pageData =
    props.data.orchard.puxLandingPage && props.data.orchard.puxLandingPage[0]

  const widgetZone = props.pageContext.widgets.landingWidgets

  const headerData: HeaderData = {
    menuItems: menuItemsMapper(pageData?.landingPageAnchors?.contentItems),
  }

  return (
    <Layout
      style='landing'
      cta={pageData?.landingPageButton}
      landingPageNav={headerData}
    >
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className={rootClass}>
        <div className={`${rootClass}-widgets`}>
          <WidgetBuilder widgetBuilderData={widgetZone} />
        </div>
      </div>
    </Layout>
  )
}

export default LandingPage
